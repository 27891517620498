<style scoped>
.time {
  font-size: 14px;
  font-weight: bold;
}
.content {
  padding-left: 5px;
}
</style>
<template>
  <div>
    <Divider dashed><span class="divider-text">审批记录</span></Divider>
    <Timeline
      id="approvalHistory"
      v-show="approvalData.length>0"
    >
      <TimelineItem
        v-for="(item,index) in approvalData"
        :key="item.id"
        :class="item.id===approvalId?'text-green':''"
        v-show="index<10||showAllhistory"
      >
        <Icon
          :type="getIconType(item.status)"
          :color="getIconColor(item.status)"
          slot="dot"
        ></Icon>
        <p class="time">{{item.createTime}}</p>
        <p class="content">{{item.description}} <a @click="handleViewApprovalDetail(item)">查看详情</a> </p>
      </TimelineItem>
      <TimelineItem v-show="approvalData.length>10"><a @click="showAllhistory=!showAllhistory">{{!showAllhistory?'查看更多':'隐藏部分记录'}}</a></TimelineItem>
    </Timeline>
    <p
      v-show="approvalData.length===0"
      class="text-center"
    >暂无数据</p>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  data () {
    return {
      pageNum: 1,
      showAllhistory: false,
      approvalList: []
    }
  },
  computed: {
    approvalData () {
      return this.$store.state.electronic.approvalRecordData
    },
    approvalId: {
      get () {
        return this.$store.state.electronic.curApprovalId
      },
      set (val) {
        this.$store.commit('set_cur_approval_id', val)
      }
    }
  },
  methods: {
    getIconType (status) {
      switch (status) {
        case 0: // 审批中
          return 'md-alarm'
        case 1: // 通过审批
          return 'md-checkmark-circle'
        case 2: // 拒绝审批
          return 'md-close-circle'
        case 3: // 撤销审批
          return 'md-undo'
        default:
          return 'md-alarm'
      }
    },
    getIconColor (status) {
      switch (status) {
        case 0:
          return '#2d8cf0'
        case 1:
          return '#19be6b'
        case 2:
          return '#ed4014'
        case 3:
          return '#ff9900'
        default:
          return '#2d8cf0'
      }
    },
    handleViewApprovalDetail (item) {
      this.approvalId = item.id
      this.handleApprovalDetailPage()
      // 设置头部路由标签
      // this.setLinkTagArray({ key: 'installList', value: '上刊设置列表', actived: true })
      this.setLinkRouterMaps(['electronicApprovalDetail', () => { this.handleApprovalDetailPage() }])
    },
    handleApprovalDetailPage () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('Material')
      this.setShowRightBlock(true)
      this.setRightComponent('ApprovalHistory')
      this.setBottomComponent('ApprovalDetail')
    }
  }
}
</script>
